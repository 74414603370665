import React, {useState, useEffect} from 'react';
import { Loader } from '../Common/Loader';
import { getChart } from '../service/service';
import moment from 'moment'

const Chart = () => {
const [loader, setLoader] = useState(false)
const [chartList, setChartList] = useState([])
const [marketName, setMarketName] = useState('')
useEffect(() => {
    handleChart()
}, [])
const handleChart = () => {
    const id = new URLSearchParams(window.location.search).get("market_id")
    const market_name = new URLSearchParams(window.location.search).get("market_name")
    setMarketName(market_name);
    // const id = "223"
    setLoader(true)
    getChart(id).then((res) => {
        setLoader(false)
        setChartList(res?.data?.data);
    }).catch((err) => {
        setLoader(false)
        alert(err || "something went wrong ")
    })
}
  return (
    <>
        {
            loader ? <Loader /> : null
        }
        <div className='chart'>
            <h3 className='text-center'>Result For {marketName}</h3>
            <div  className='chart-grid'>
                {chartList?.map((item, index) => {
                    return (
                        <div className='chart-table' key={index}>
                            <div className='date'>{moment(item?.result_date).format("ddd DD-MM-YYYY")}</div>
                            <div className='result'>{item?.open_pana_result+item?.close_pana_result}</div>
                        </div>
                    )
                })} 
            </div>
        </div>
    </>
  )
}

export default Chart