import React, { useState, useEffect } from 'react';
import { barCode, getbarCode } from '../service/service';
import { Loader } from '../Common/Loader';

const QrImage = () => {
  const [qrData, setQrData] = useState({});
  const [loader, setLoader] = useState(false);
  const [isChecked, setisChecked] = useState(false);

  useEffect(() => {
    const fetchQrData = async () => {
      try {
        const response = await getbarCode();
        setQrData(response.data.data);
      } catch (error) {
        console.error('Error fetching QR data:', error);
      } finally {
        setLoader(false);
      }
    };
    fetchQrData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQrData((prevQrData) => ({ ...prevQrData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setQrData((prevQrData) => ({ ...prevQrData, [name]: checked }));
  };

  const handleUPIChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 10) {
      setQrData((prevQrData) => ({ ...prevQrData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await barCode(qrData);
      alert('Update Successful');
      console.log('Response:', response);
    } catch (error) {
      console.error('Error updating QR data:', error);
      alert('Internal Server Error');
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="content-wrapper">
      {loader ? <Loader /> : null}
      <div className="card h100p mb-4">
        <div className="card-body">
          <h4 className="card-title">Update New QR</h4>
          <form className="theme-form mega-form" id="adminUPIFrm" name="adminUPIFrm">
            <div className="row">
              <div className="form-group col-12">
                <label className="col-form-label">QR Image URL</label>
                <input
                  className="form-control"
                  type="text"
                  name="image"
                  value={qrData.image}
                  onChange={handleInputChange}
                  placeholder="Paste Image URL"
                />
              </div>
              <div className="form-group col-12">
                <label className="col-form-label">UPI Number</label>
                <input
                  className="form-control"
                  type="number"
                  name="number"
                  value={qrData.number}
                  onChange={handleUPIChange}
                  placeholder="Enter Merchant No"
                />
              </div>
              <div className="form-group col-12 ml-3">
                <input
                  className="custom-control-input"
                  type="checkbox"
                  name="status"
                  checked={qrData.status}
                  id="qr_status"
                  onChange={handleCheckboxChange}
                />
                <label className="custom-control-label pl-2" htmlFor="qr_status">
                  QR Status
                </label>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={!qrData.image || !qrData.number || !qrData.status}
                onClick={handleSubmit}
                className="btn btn-danger waves-light m-t-10"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QrImage;