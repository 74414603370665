import React, { useEffect, useState } from 'react'
import { Loader } from '../Common/Loader'
import { addGameApi, deleteGame, gameNameApi, updateGameName } from '../service/service';
import DataTable, { createTheme } from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const GameName = () => {
  const [data, setData] = useState([])
  const[loader,setLoader]=useState(false)
  const[show,setShow]=useState(false)
  const[edit,setEdit]=useState()
  const[marketEdit,setMarketEdit]=useState()
  const[newGameName,setnewGameName]=useState("")
  const[gameId,setGameId]=useState("")
  const[formInput,setFormInput]=useState("")

  const handleInput=(e)=>{
    const{name,value}=e.target;
    console.log("pppp",e.target.value)
    setFormInput({...formInput,[name]:value})
  }

  const handleSubmitEdit =(value)=>{
      
    setLoader(true)
    updateGameName(value).then((res)=>{
      setLoader(false)
      handleClose()
      handleGameNameList()
      
    }).catch((err) => {
      setLoader(false)
      alert(err || "something went wrong ")
    })


  }
  const handleMarketOffEdit =(value)=>{
      
    setLoader(true)
    updateGameName(value).then((res)=>{
      setLoader(false)
      handleClose()      
    }).catch((err) => {
      setLoader(false)
      alert(err || "something went wrong ")
    })


  }
  const handleClose =()=>{
    setShow(false)
  }
  useEffect(() => {
    handleGameNameList()
  }, [])

  const handleGameNameList = () => {
    setLoader(true)
    gameNameApi("normal").then((res) => {
      setLoader(false)
      setData(res.data.data)
      console.log(res.data.data, "lisst");
    }).catch((err) => {
      setLoader(false)
      alert(err || "something went wrong ")
    })
  }
  const handleDeleteGame=(id)=>{
    setLoader(true)
    deleteGame(id).then((res)=>{
      console.log(res)
      handleGameNameList()
    }).catch((err) => {
      setLoader(false)
      alert(err || "something went wrong ")
    })
  }
  const columns = [
    {
      name: 'Sr No',
      cell: (row,index) => {
        return (
          <p >
            {index+1}
          </p>
        )
      },
      width: "10%",

      sortable: true,

    },
    {
      name: 'Game Name',
      selector: row => (row?.market_name || "NA"),
      sortable: true,
      width: "11%",

    },
    {
      name: 'Today Open',
      selector: row => row.market_opening_time,
      width: "11%",
      sortable: true,
    },
    {
      name: 'Today Close',
      selector: row => row.market_closing_time,
      width: "11%",
      sortable: true,
    },

    {
      name: 'Active',

      cell: (row, index) => {


        return (
          <span className={row?.active ? "btnspan success" : "btnspan danger"} 
          // onClick={()=>{
          //   handleSubmitEdit({
          //     active:!row?.active?"True":"False" ,
          //     id:row?.id
          //   })
          // }}
          >
            {row?.active ? "Yes" : "No"}
          </span>
        )
      },
      width: "11%",
    },

    {
      name: 'Market Status',

      cell: (row, index) => {


        return (
          <span className={row?.market_status ? "btnspan success" : "btnspan danger"}>
            {row?.market_status ? "Open Today" : "Close Today"}
          </span>
        )
      },
      width: "20%"
    },
    {
      name: 'Action',

      cell: (row, index) => {
        return (
          <span className='d-flex ' >
            <button className='btn btn-danger m-1 btn-sm' onClick={()=>{
              setGameId(row.id)
              setEdit(true)
            }
              }>
              Edit
            </button>
            <button className='btn btn-danger m-1 btn-sm' onClick={()=>{
              setGameId(row.id)
              setMarketEdit(true)
              setFormInput({
                monday:row?.market_off_day?.monday?.status, 
                monday_open_time: row?.market_off_day?.monday?.open_time, 
                monday_close_time: row?.market_off_day?.monday?.close_time,
                tuesday:row?.market_off_day?.tuesday?.status, 
                tuesday_open_time: row?.market_off_day?.tuesday?.open_time, 
                tuesday_close_time: row?.market_off_day?.tuesday?.close_time,
                wednesday:row?.market_off_day?.wednesday?.status, 
                wednesday_open_time: row?.market_off_day?.wednesday?.open_time, 
                wednesday_close_time: row?.market_off_day?.wednesday?.close_time,
                thursday:row?.market_off_day?.thursday?.status, 
                thursday_open_time: row?.market_off_day?.thursday?.open_time, 
                thursday_close_time: row?.market_off_day?.thursday?.close_time,
                friday:row?.market_off_day?.friday?.status, 
                friday_open_time: row?.market_off_day?.friday?.open_time, 
                friday_close_time: row?.market_off_day?.friday?.close_time,
                saturday:row?.market_off_day?.saturday?.status, 
                saturday_open_time: row?.market_off_day?.saturday?.open_time, 
                saturday_close_time: row?.market_off_day?.saturday?.close_time,
                sunday:row?.market_off_day?.sunday?.status, 
                sunday_open_time: row?.market_off_day?.sunday?.open_time, 
                sunday_close_time: row?.market_off_day?.sunday?.close_time,
              })
            }
              }>
              Market Off Today
            </button>
            <button onClick={()=>{
              handleDeleteGame(row.id)
            }} className='btn btn-danger m-1 btn-sm'>
              Delete
            </button>
          </span>
        )
      },
      width: "30%",
      center: true,

    },


  ];
  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  });


const handleAddGame =()=>{
  setLoader(true)
  let data ={
    market_name: formInput?.gamename,
    market_code: "",
    active: true,
    market_status: true,
    market_opening_time: formInput?.open_time,
    market_type: "NORMAL",
    market_closing_time: formInput?.close_time,
    market_off_day: {
      "monday": {
        "open_time": formInput?.open_time,
        "close_time": formInput?.close_time,
        "status": true
      },
      "tuesday": {
        "open_time": formInput?.open_time,
        "close_time": formInput?.close_time,
        "status": true
      },
      "wednesday": {
        "open_time": formInput?.open_time,
        "close_time": formInput?.close_time,
        "status": true
      },
      "thursday": {
        "open_time": formInput?.open_time,
        "close_time": formInput?.close_time,
        "status": true
      },
      "friday": {
        "open_time": formInput?.open_time,
        "close_time": formInput?.close_time,
        "status": true
      },
      "saturday": {
        "open_time": formInput?.open_time,
        "close_time": formInput?.close_time,
        "status": true
      },
      "sunday": {
        "open_time": formInput?.open_time,
        "close_time": formInput?.close_time,
        "status": true
      }
    }
  }
  addGameApi(data).then((res)=>{
  console.log("res",res)
  setLoader(false)
  handleGameNameList()
  handleClose()
  }).catch((err) => {
    setLoader(false)
    alert(err || "something went wrong ")
  })
}

  return (
    <div className="content-wrapper">
      {loader?<Loader/>:null}

      <div className="container-fluid">
        <div className="card mb-3">
          <div className="card-header flex align-center space-between">
            <div><i className="fa fa-table"></i> <b>Game Name List</b></div>
            <div className='add-name'>
              <button className='btn btn-danger m-1 btn-sm' onClick={()=>setShow(true)}>Add Game</button>
            </div>

          </div>


        </div>
      </div>
      <div >
        <DataTable
          columns={columns}
          data={data}
          pagination
          sortable
        />
      </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input name="gamename" type="text" value={formInput?.gamename} onWheel={(e) => e.target.blur()} onChange={handleInput} placeholder="Enter Game Name " className="form-control"/><br/>
                  <input name="open_time" type="time" step={900} value={formInput?.open_time} onWheel={(e) => e.target.blur()} onChange={handleInput} placeholder="open time " className="form-control"/><br/>
                  <input name="close_time" type="time" step={900} value={formInput?.close_time} onWheel={(e) => e.target.blur()} onChange={handleInput} placeholder="close time " className="form-control"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" disabled={!(formInput?.gamename && formInput?.open_time && formInput?.close_time)} onClick={(e)=>{
                      e.preventDefault()
                      handleAddGame()
                    }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={edit} onHide={()=>setEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Game Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input name="game" type="text" onWheel={(e) => e.target.blur()} placeholder="Enter Game Name " className="form-control" onChange={(e)=>{
                    setnewGameName(e.target.value)
                  }}/><br/>
                  
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setEdit(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={()=>{
                      handleSubmitEdit({
                        market_name:newGameName,
                        id:gameId
                      })
                    }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={marketEdit} onHide={()=>setMarketEdit(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Market Day Off</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='off-table'>
                      <div className='row mb-3'>
                        <div className='col-md-2'>
                          <label style={{display:"block"}}>Monday</label>
                          <input type="checkbox" name="day[]" checked={formInput?.monday} value='monday' onChange={()=>setFormInput({...formInput, monday: !formInput.monday})}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Open Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, monday_open_time:e.target.value})} value={formInput?.monday_open_time}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Close Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, monday_close_time:e.target.value})} value={formInput?.monday_close_time}/>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-2'>
                          <label style={{display:"block"}}>Tuesday</label>
                          <input type="checkbox" name="day[]" checked={formInput?.tuesday} value='tuesday' onChange={()=>setFormInput({...formInput, tuesday: !formInput.tuesday})}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Open Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, tuesday_open_time:e.target.value})} value={formInput?.tuesday_open_time}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Close Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, tuesday_close_time:e.target.value})} value={formInput?.tuesday_close_time}/>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-2'>
                          <label style={{display:"block"}}>Wednesday</label>
                          <input type="checkbox" name="day[]" checked={formInput?.wednesday} onChange={()=>setFormInput({...formInput, wednesday: !formInput.wednesday})}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Open Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, wednesday_open_time:e.target.value})} value={formInput?.wednesday_open_time}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Close Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, wednesday_close_time:e.target.value})} value={formInput?.wednesday_close_time}/>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-2'>
                          <label style={{display:"block"}}>Thursday</label>
                          <input type="checkbox" name="day[]" checked={formInput?.thursday} onChange={()=>setFormInput({...formInput, thursday: !formInput.thursday})}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Open Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, thursday_open_time:e.target.value})} value={formInput?.thursday_open_time}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Close Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, thursday_close_time:e.target.value})} value={formInput?.thursday_close_time}/>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-2'>
                          <label style={{display:"block"}}>Friday</label>
                          <input type="checkbox" name="day[]" checked={formInput?.friday} onChange={()=>setFormInput({...formInput, friday: !formInput.friday})}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Open Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, friday_open_time:e.target.value})} value={formInput?.friday_open_time}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Close Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, friday_close_time:e.target.value})} value={formInput?.friday_close_time}/>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-2'>
                          <label style={{display:"block"}}>Saturday</label>
                          <input type="checkbox" name="day[]" checked={formInput?.saturday} onChange={()=>setFormInput({...formInput, saturday: !formInput.saturday})}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Open Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, saturday_open_time:e.target.value})} value={formInput?.saturday_open_time}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Close Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, saturday_close_time:e.target.value})} value={formInput?.saturday_close_time}/>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <div className='col-md-2'>
                          <label style={{display:"block"}}>Sunday</label>
                          <input type="checkbox" name="day[]" checked={formInput?.sunday} onChange={()=>setFormInput({...formInput, sunday: !formInput.sunday})}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Open Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, sunday_open_time:e.target.value})} value={formInput?.sunday_open_time}/>
                        </div>
                        <div className='col-md-1'></div>
                        <div className='col-md-4'>
                          <label style={{display:"block"}}>Close Time</label>
                          <input name="open_time" id="open_time" class="form-control digits" type="time" onChange={(e)=> setFormInput({...formInput, sunday_close_time:e.target.value})} value={formInput?.sunday_close_time}/>
                        </div>
                      </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setMarketEdit(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={()=>{
                      handleMarketOffEdit({
                        id:gameId,
                        market_off_day:{
                          "monday": {
                            "open_time": formInput?.monday_open_time,
                            "close_time": formInput?.monday_close_time,
                            "status": formInput?.monday
                          },
                          "tuesday": {
                            "open_time": formInput?.tuesday_open_time,
                            "close_time": formInput?.tuesday_close_time,
                            "status": formInput?.tuesday
                          },
                          "wednesday": {
                            "open_time": formInput?.wednesday_open_time,
                            "close_time": formInput?.wednesday_close_time,
                            "status": formInput?.wednesday
                          },
                          "thursday": {
                            "open_time": formInput?.thursday_open_time,
                            "close_time": formInput?.thursday_close_time,
                            "status": formInput?.thursday
                          },
                          "friday": {
                            "open_time": formInput?.friday_open_time,
                            "close_time": formInput?.friday_close_time,
                            "status": formInput?.friday
                          },
                          "saturday": {
                            "open_time": formInput?.saturday_open_time,
                            "close_time": formInput?.saturday_close_time,
                            "status": formInput?.saturday
                          },
                          "sunday": {
                            "open_time": formInput?.sunday_open_time,
                            "close_time": formInput?.sunday_close_time,
                            "status": formInput?.sunday
                          }
                        }
                        
                      })
                    }}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

    </div>
  )
}

export default GameName
