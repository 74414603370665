import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Loader } from '../Common/Loader'
import Modal from 'react-bootstrap/Modal';
import { gameNameApi, getAddNumbers, getNumbers, resultDeclareAPi, getresultList, deleteGameResult, showWinner } from '../service/service'

const Resultdeclare = () => {
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const[show,setShow]=useState(false)
    const [selectsession, setSelectSession] = useState("0")
    const [numberSum, setNumberSum] = useState("")
    const [numberList, setNumberList] = useState([])
    const [showResultDeclare, setShowResultDeclare] = useState(false)
    const [dateSelect, setDateSelect] = useState(moment().format("YYYY-MM-DD"))
    const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"))
    const [selectedGameName, setSelectedGameName] = useState("")
    const [marketId, setMarketId] = useState("")
    const [resultList, setResultList] = useState([])
    const [totalWinning, setTotalWinning] = useState('')
    const [totalBidAmount, setTotalBidAmount] = useState('')
    const [winList, setWinList] = useState([])
    const [selectedNumberOpen, setselectedNumberOpen] = useState("")
    const [selectedNumberClose, setselectedNumberClose] = useState("")

    useEffect(() => {
        handleGetGameList()
        handleGetPanalist()
        handleGetResultList()
    }, [])

    const handleClose =()=>{
        setShow(false)
      }

   
    const handleGetGameList = () => {
        setLoader(true)
        gameNameApi("normal").then((res) => {
            setData(res?.data?.data)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log("err", err)
            alert(err?.response?.data?.message || "Internal server error")
        })
    }

    const handleGetPanalist = () => {
        getNumbers("FULL_SANGAM").then((res) => {
            setNumberList(res?.data?.data[0])
        }).catch((err) => {
            console.log("error", err)
        })
    }

    const handleGetAddNumbers = (id, type) => {
       
        if(type==="1"){
            setselectedNumberOpen(id)
        }
        if(type!=="1"){
            setselectedNumberClose(id)
        }

        setLoader(true)
        let key = type === "1" ? "open" : "close";
        getAddNumbers(id).then((res) => {
           
            setNumberSum({ ...numberSum, [key]: res?.data?.digit })
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log("error", err)

        })
    }


    const handleDeclaireResultOpen = () => {
        localStorage.setItem("OpenREsult", selectedNumberOpen+"-"+numberSum?.open)
        alert("open")
        let data = {
            market: marketId,
            game_name: selectedGameName || null,
            result_date: dateSelect,
            open_declare_date: moment().add(5, 'hours').add(30, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            open_pana_result:selectedNumberOpen+"-"+numberSum?.open,

        }
        resultDeclareAPi(data).then((res) => {
            handleGetResultList()

        }).catch((err) => {
            setLoader(false)
            console.log("error", err)

        })

    }
    const handleShowWinnerOpen = () => {
        console.log("showWinnerOPen");
        setShow(true)
        localStorage.setItem("OpenREsult", selectedNumberOpen+"-"+numberSum?.open)
        let data = {
            market: marketId,
            game_name: selectedGameName || null,
            result_date: dateSelect,
            open_declare_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            open_pana_result:selectedNumberOpen+"-"+numberSum?.open,
        }
        showWinner(data).then((res) => {
            console.log(res, res?.data?.data)
            setTotalWinning(res?.data?.total_winning)
            setTotalBidAmount(res?.data?.total_bid_amount)
            setWinList(res?.data?.data)
        }).catch((err) => {
            setLoader(false)
            console.log("error", err)
        })
    }

    const handleDeclaireResultClose = () => {
        alert("close")
        let data = {
            market: marketId,
            game_name: selectedGameName || null,
            result_date: dateSelect,
            close_declare_date: moment().add(5, 'hours').add(30, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            close_pana_result: numberSum?.close+"-"+selectedNumberClose,
        }
        resultDeclareAPi(data).then((res) => {
            console.log("result", res)
            
            handleGetResultList()
        }).catch((err) => {
            setLoader(false)
            console.log("error", err)
            alert(err?.response?.data?.message)

        })
    }

    const handleShowWinnerClose = () => {
        console.log("showWinnerCLose");
        setShow(true)
        let data = {
            market: marketId,
            game_name: selectedGameName || null,
            result_date: dateSelect,
            close_declare_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            close_pana_result: numberSum?.close+"-"+selectedNumberClose,
            open_declare_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
            open_pana_result:localStorage.getItem("OpenREsult"),
        }
        showWinner(data).then((res) => {
            console.log(res, res?.data?.data)
            setTotalWinning(res?.data?.total_winning)
            setTotalBidAmount(res?.data?.total_bid_amount)
            setWinList(res?.data?.data)
        }).catch((err) => {
            setLoader(false)
            console.log("error", err)
        })
    }

    const handleGetResultList = (start_date,end_date) => {
        setLoader(true)
        setselectedNumberClose("")
        setselectedNumberOpen("")
        setNumberSum({open:"",close:""})
        // let start_date = moment(filterDate).format("YYYY-MM-DD")
        // let end_date = moment(filterDate).format("YYYY-MM-DD")
        getresultList(start_date || moment().format("YYYY-MM-DD"),end_date || moment().format("YYYY-MM-DD")).then((res) => {
            setResultList(res?.data?.data)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
           
            console.log("error", err)
        })
    }
 const handleDelete =(id,isOpen)=>{
    setLoader(true)
    deleteGameResult(id,isOpen).then((res)=>{
   
        handleGetResultList()
       setLoader(false)
    }).catch((err) => {
        setLoader(false)
        console.log("error", err)
    })
}
const handlefilterTable = (e) => {
    setFilterDate(moment(e.target.value).format("YYYY-MM-DD"))
    let start_date = moment(e.target.value).format("YYYY-MM-DD")
    let end_date = moment(e.target.value).format("YYYY-MM-DD")
    setTimeout(() => {
        handleGetResultList(start_date,end_date)
      }, "1000");
}
    return (
        <>
            {
                loader ? <Loader /> : null
            }
            <div className="content-wrapper">
                <div className='container-fluid'>
                    <div className='card p-3 flex align-center space-between mb-3'>
                        <h4 className="card-title text-left w-100">Select Game</h4>
                        <div className='row w-100'>
                            <div className='form-group col-md-3'>
                                <input type="date" id="start" className='form-control' value={dateSelect} onChange={(e) => {
                                    setDateSelect(moment(e.target.value).format("YYYY-MM-DD"))
                                }} />
                            </div>
                            <div className='form-group col-md-4'>
                                <select className="form-select" aria-label="Default select example" value={selectedGameName+"|"+marketId} onChange={(e) => {
                                    setSelectedGameName(e.target.value.split("|")[0])
                                    setMarketId(e.target.value.split("|")[1])
                                }}>
                                    <option selected>Select Game Name</option>
                                    {data?.map((item, index) => {
                                        return (
                                            <option key={index} value={item?.market_name + "|" + item?.id}>{`${item?.market_name || ""} (${item?.market_opening_time}-${item?.market_closing_time})`}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className='form-group col-md-3'>
                                <select className="form-select" value={selectsession} aria-label="Default select example" onChange={(e) => setSelectSession(e.target.value)}>
                                    <option value="0" >Both Session</option>
                                    <option value="1">open</option>
                                    <option value="2">close</option>
                                </select>
                            </div>
                            <div className='form-group col-md-2'>
                                <button type="submit" className="btn btn-danger btn-block" id="srchBtn" name="srchBtn" onClick={() => {
                                    setShowResultDeclare(true)
                                    setNumberSum("")

                                }}>Go</button>
                            </div>
                        </div>
                    </div>
                    {
                        showResultDeclare ?

                            <div className='card p-3 flex align-center space-between mb-3'>
                                <h4 className="card-title text-left w-100">Declare Result</h4>
                                {selectsession !== "0" ?
                                    <div className='row w-100'>
                                        <div className='form-group col-md-4'>
                                            <select className="form-select" aria-label="Default select example" value={selectedNumberOpen||selectedNumberClose} onChange={(e) => {
                                                handleGetAddNumbers(e.target.value, selectsession)
                                            }}>
                                                <option selected>{"Select " + (selectsession === "1" ? "Open" : "Close") + " Panna"}</option>
                                                {
                                                    selectsession === "1" ? numberList?.open_ank?.map((item, index) => {
                                                        return (
                                                            <option value={item}>{item}</option>
                                                        )
                                                    })
                                                        : numberList?.close_ank?.map((item, index) => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })
                                                }
                                            </select>
                                        </div>
                                        <div className='form-group col-md-3'>
                                            <input type="text" id="start" className='form-control' value={numberSum?.close || numberSum?.open} placeholder='Digit' />
                                        </div>
                                        <div className='form-group col-md-2'>
                                            <button type="submit" className="btn btn-danger btn-block" disabled={!(selectedNumberOpen||selectedNumberClose)} id="srchBtn" name="srchBtn" onClick={(e) => {
                                                e.preventDefault()
                                                selectsession === "1" ? handleShowWinnerOpen() : handleShowWinnerClose();
                                            }}>Show Winner</button>
                                        </div>
                                        <div className='form-group col-md-2'>
                                            <button type="submit" className="btn btn-danger btn-block" disabled={!(selectedNumberOpen||selectedNumberClose)} id="srchBtn" name="srchBtn" onClick={(e) => {
                                                e.preventDefault()
                                                selectsession === "1" ? handleDeclaireResultOpen() : handleDeclaireResultClose();
                                            }}>Declare</button>
                                        </div>
                                    </div> 
                                    :
                                    <>  <div className='row w-100'>
                                        <div className='form-group col-md-4'>
                                            <select className="form-select" aria-label="Default select example" value={selectedNumberOpen} onChange={(e) => {
                                                handleGetAddNumbers(e.target.value, "1")
                                            }}>
                                                <option selected>Select Open Panna</option>
                                                {
                                                    numberList?.open_ank?.map((item, index) => {
                                                        return (
                                                            <option value={item}>{item}</option>
                                                        )
                                                    })

                                                }
                                            </select>
                                        </div>
                                        <div className='form-group col-md-3'>
                                            <input type="text" id="start" className='form-control' value={numberSum?.open} placeholder='Digit' />
                                        </div>
                                        <div className='form-group col-md-2'>
                                            <button type="submit" className="btn btn-danger btn-block" disabled={!(selectedNumberOpen||selectedNumberClose)} id="srchBtn" name="srchBtn" onClick={(e) => {
                                                e.preventDefault()
                                                handleShowWinnerOpen()
                                            }}>Show Winner</button>
                                        </div>
                                        <div className='form-group col-md-2'>
                                            <button type="submit" className="btn btn-danger btn-block" id="srchBtn" name="srchBtn" onClick={handleDeclaireResultOpen}>Declare</button>
                                        </div>
                                    </div>
                                        <br></br>
                                        <div className='row w-100'>
                                            <div className='form-group col-md-4'>
                                                <select className="form-select" aria-label="Default select example" value={selectedNumberClose} onChange={(e) => {
                                                    handleGetAddNumbers(e.target.value, "2")
                                                }}>
                                                    <option selected>Select Close Panna </option>
                                                    {
                                                        numberList?.close_ank?.map((item, index) => {
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })

                                                    }
                                                </select>
                                            </div>
                                            <div className='form-group col-md-3'>
                                                <input type="text" id="start" className='form-control' value={numberSum?.close} placeholder='Digit' />
                                            </div>
                                            <div className='form-group col-md-2'>
                                                <button type="submit" className="btn btn-danger btn-block" disabled={!(selectedNumberOpen||selectedNumberClose)} id="srchBtn" name="srchBtn" onClick={(e) => {
                                                    e.preventDefault()
                                                    handleShowWinnerClose()
                                                }}>Show Winner</button>
                                            </div>
                                            <div className='form-group col-md-2'>
                                                <button type="submit" className="btn btn-danger btn-block" id="srchBtn" name="srchBtn" onClick={handleDeclaireResultClose}>Declare</button>
                                            </div>
                                        </div></>}
                            </div>
                            : null}
                    <div className='card p-3 flex align-center space-between'>
                        <h4 className="card-title text-left w-100">Game Result History</h4>
                        <div className='row w-100'>
                            <div className='form-group col-md-3'>
                                <label>Select Result Date</label>
                                <input type="date" id="start" className='form-control' value={filterDate} onChange={handlefilterTable} />
                            </div>
                            <div className='form-group col-md-9'></div>
                        </div>
                    </div>

                    <div className='card p-3 flex align-center space-between'>
                        <h4 className="card-title text-left w-100">Game Result List</h4>
                        <div className='row w-100'>

                           
                                <table
                                    className="table table-bordered"
                                    id="dataTable"
                                    width="100%"
                                    cellspacing="0"
                                    cellpadding="0"
                                >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Game Name</th>
                                            <th>Result Date</th>
                                            <th>Open Declare Date</th>
                                            <th>Close Declare Date</th>
                                            <th>Open Pana</th>
                                            <th>Close Pana</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            resultList?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index}</td>
                                                        <td>{item?.game_name || "NA"}</td>
                                                        <td>{moment(item?.result_date).format("DD-MM-YYYY")}</td>
                                                        <td>{moment(item?.open_declare_date).format("ddd DD-MMM-YYYY, hh:mm A") || "NA"}</td>
                                                        <td>{moment(item?.close_declare_date ).format("ddd DD-MMM-YYYY, hh:mm A")|| "NA"}</td>
                                                        <td>{item?.open_pana_result || "NA"}
                                                        &nbsp;&nbsp;
                                                        <Button onClick={()=>{
                                                            handleDelete(item?.id,false)
                                                        }} style={{fontSize:"12px"}} className='btn btn-sm btn-danger'>Delete</Button>
                                                       
                                                        </td>
                                                        <td>{item?.close_pana_result || "NA"}
                                                        &nbsp;&nbsp;
                                                        <Button onClick={()=>{
                                                            handleDelete(item?.id,true)
                                                        }} style={{fontSize:"12px"}} className='btn btn-sm btn-danger'>Delete</Button>
                                                        </td>

                                                    </tr>
                                                )
                                            })
                                        }


                                    </tbody>
                                </table>
                           
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Winner List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-6'>Total Bid Amount : {totalBidAmount || 0}</div>
                        <div className='col-md-6'>Total Winning Amount : {totalWinning || 0}</div>
                    </div>
                    <table class="table table-striped table-bordered">
						<thead>
                            <tr>
                                <th>#</th>
								<th>User Name</th>
								<th>Bid Points</th>
								<th>Winning Amount</th>
								<th>Type</th>
								<th>Session</th>
                            </tr>
                        </thead>
                        <tbody id="winner_result_data">
                            {
                                winList?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item?.user_name}</td>
                                            <td>{item?.bid_points}</td>
                                            <td>{item?.won_points}</td>
                                            <td>{item?.game_type}</td>
                                            <td>{item?.session?"Open":"Close"}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colspan="6" class="table_text_align">No Data Available</td>
                            </tr>
                        </tbody>
					</table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Resultdeclare